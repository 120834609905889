<template>
  <Swiper
    class="w-full rounded-5 lg:rounded-10"
    v-bind="(options as any)"
  >
    <SwiperPagination
      class="absolute bottom-6 left-6 lg:bottom-8 lg:left-11 z-1"
      :autoplay="true"
    />
    <SwiperSlide
      v-for="(item, index) in bannersRef"
      :key="index"
      class="flex flex-col gap-8 relative overflow-hidden p-6 pb-10 lg:p-11 lg:pb-11 min-h-60 lg:min-h-82.5 text-tekstovyy"
    >
      <picture>
        <nuxt-source
          media="(min-width: 768px)"
          :src="item.img_path"
          sizes="1024px lg:1320px"
        />
        <nuxt-source :src="item.img_mobile_path" />
        <NuxtImg
          class="absolute top-0 left-0 w-full h-full object-cover -z-1"
          :src="item.img_path"
          format="png"
          alt=""
        />
      </picture>
      <h1 class="text-8.5 lg:text-16 font-700 leading-1 -tracking-0.04em">{{ item.title }}</h1>
      <p class="max-w-40 lg:max-w-100 text-4.5 lg:text-7 leading-1.2 -tracking-0.04em">{{ item.message }}</p>
    </SwiperSlide>
  </Swiper>
</template>

<script setup lang="ts">

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Autoplay } from 'swiper/modules';
import type { SwiperOptions } from 'swiper/types';

import { useBanners } from '~/composables/banner/useBanner';
import type { BannerType } from '~/composables/banner/types/BannerTypes';

const { getBanners } = useBanners();

const bannersRef = ref<BannerType[] | []>([]);

const getBannersToRef = async () => {
  const res = await getBanners();
  if (res) {
    bannersRef.value = res;
  }
};

onBeforeMount(() => {
  getBannersToRef();
});

const options: SwiperOptions = {
  modules: [Pagination, Autoplay],
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  autoplay: {
    delay: 3000
  },
  speed: 600,
  rewind: true
};
</script>
